@import '../../style/variables';

.wrapper {
	display: flex;
	align-items: center;
	justify-content: center;
}

.error {
	margin: 4rem;
	padding: 3rem;
	background: $color-greyscale-100;
	box-shadow: $box-shadow;
}

.message {
	font-size: $font-size-5;
	margin-bottom: 2rem
}

:global(.darkMode) {
	.error {
		background: $color-darkmode-greyscale-800;
		box-shadow: $box-shadow-dark;
		color: $color-darkmode-greyscale-200;
	}
}