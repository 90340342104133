@import '../../style/helpers';
@import '../../style/variables';

.topBar {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	font-size: $font-size-2;
	padding: 1rem var(--pad-container);

	@include media(smallerLandscape) {
		padding: .5rem var(--pad-container);
	}
}

.logo {
	margin-right: .5rem;

	svg {
		width: 2rem;
	}

}

.header {
	
	a {
		display: flex;
		text-decoration: none;
		color: inherit;
		align-items: center;
	}
}

.heading {
	display: none;
	font-size: $font-size-4;
	font-weight: 300;
	color: $color-greyscale-400;

	@media screen and (min-width: $bp-small) {
		display: inline-block;
	}
}

.links {
	display: flex;

	& > * {
		margin-right: .75rem;

		&:last-child {
			margin-right: 0;
		}
	}

}

.link {
	text-decoration: none;
}