@import '../../style/variables';

.logo {
	path {
		stroke: #d0e0ea;
		fill: #cad6de;
	}

	rect {
		fill: $color-go-500;
	}
}

:global(.darkMode) {
	.logo {
		path {
			stroke: $color-greyscale-300;
			fill: $color-greyscale-300;
		}

		rect {
			fill: $color-go-700;
		}
	}	
}