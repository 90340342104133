@use 'sass:color';
@import './helpers';

$color-greyscale-100: hsl(0, 0%, 100%);
$color-greyscale-200: hsl(210, 29%, 97%);
$color-greyscale-300: hsl(203, 26%, 88%);
$color-greyscale-400: hsl(203, 26%, 79%);
$color-greyscale-500: hsl(218, 11%, 71%);
$color-greyscale-600: hsl(207, 11%, 67%);
$color-greyscale-700: hsl(202, 12%, 59%);
$color-greyscale-800: hsl(205, 12%, 51%);
$color-greyscale-900: hsl(207, 11%, 43%);
$color-greyscale-1000: hsl(207, 16%, 22%);

$color-darkmode-greyscale-100: hsl(0, 0%, 91%);
$color-darkmode-greyscale-200: hsl(210, 20%, 88%);
$color-darkmode-greyscale-300: hsl(203, 16%, 64%);
$color-darkmode-greyscale-400: hsl(203, 11%, 50%);
$color-darkmode-greyscale-500: hsl(218, 11%, 45%);
$color-darkmode-greyscale-600: hsl(207, 11%, 30%);
$color-darkmode-greyscale-700: hsl(202, 12%, 18%);
$color-darkmode-greyscale-800: hsl(205, 12%, 15%);
$color-darkmode-greyscale-850: hsl(210, 9.7%, 12.2%);
$color-darkmode-greyscale-900: hsl(207, 16%, 8%);

$color-rest-100: hsl(0, 86%, 91%);
$color-rest-200: hsl(0, 86%, 86%);
$color-rest-300: hsl(0, 86%, 81%);
$color-rest-400: hsl(0, 86%, 75%);
$color-rest-500: hsl(0, 86%, 70%);
$color-rest-600: hsl(0, 80%, 67%);
$color-rest-700: hsl(0, 75%, 59%);
$color-rest-800: hsl(0, 65%, 53%);
$color-rest-900: hsl(0, 55%, 45%);

$color-go-100: hsl(97, 86%, 91%);
$color-go-200: hsl(97, 86%, 86%);
$color-go-300: hsl(97, 86%, 81%);
$color-go-400: hsl(97, 86%, 75%);
$color-go-500: hsl(97, 86%, 70%);
$color-go-600: hsl(97, 80%, 67%);
$color-go-700: hsl(97, 75%, 59%);
$color-go-800: hsl(97, 65%, 53%);
$color-go-900: hsl(97, 55%, 45%);

$font-size-1: .80rem;
$font-size-2: 1rem;
$font-size-3: 1.25rem;
$font-size-4: 1.5rem;
$font-size-5: 2rem;
$font-size-6: 2.5rem;
$font-size-7: 3.5rem;
$font-size-8: 4rem;
$font-size-9: 5rem;

$bp-smaller: 480px;
$bp-small: 720px;
$bp-medium: 1200px;
$bp-large: 1800px;
$bp-larger: 2500px;

$bp-smaller-w: $bp-smaller;
$bp-smaller-h: 474px;
$bp-small-w: $bp-small;
$bp-small-h: 573px;
$bp-medium-w: $bp-medium;
$bp-medium-h: 622px;
$bp-large-w: $bp-large;
$bp-large-h: 802px;
$bp-larger-w: $bp-larger;
$bp-larger-h: 992px;

$border-radius: 8px;
$pad-base: 1rem;
$pad-large: 4rem;

$box-shadow: 8px 8px 20px 0px color.change($color-greyscale-800, $alpha: 0.15), 0px 4px 4px 0px color.change($color-greyscale-300, $alpha: 0.20);

$box-shadow-dark: 8px 8px 20px 0px color.change(#000, $alpha: .20), 0px 4px 4px 0px color.change(#111, $alpha: 0.15);

:root {
	--pad-container: 1rem;

	@include media(medium) {
		--pad-container: 1.25rem;
	}

	@include media(large) {
		--pad-container: 1.5rem;
	}

	@include media(larger) {
		--pad-container: 1.75rem;
	}

	--pad-base: 1rem;
	--pad-small: .5rem;
	--pad-large: 3rem;
	--radius: 8px;
	--top-bar-height: 3.5rem;

	--font-family-sans: 'Roboto', arial, sans-serif;
}

.theme-dark {
}