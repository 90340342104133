@import "../../style/variables";

.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.8rem;
  color: $color-greyscale-300;
  background: $color-greyscale-1000;

  a:hover {
    color: $color-greyscale-100;
  }

  :global(.darkMode) & {
    background: $color-darkmode-greyscale-900;
  }
}

.list {
  display: flex;
}

.listItem {
  display: flex;
  align-items: center;
  margin: 0.25rem 0;
  padding: 0 0;

  &::before {
    content: "■";
    color: $color-greyscale-900;
    font-size: 0.5rem;
    margin: 0 0.75rem;
  }

  &:first-child {
    margin-left: 1rem;
    padding-left: 0;

    &::before {
      content: "";
      margin: 0;
    }
  }
}

.listLink {
  display: flex;
  align-items: center;

  svg {
    width: 1.25rem;
    height: 1.25rem;

    &:nth-child(2) {
      display: none;
    }
  }

  &:hover {
    svg:nth-child(1) {
      display: none;
    }
    svg:nth-child(2) {
      display: block;
    }
  }
}
