@import '../../style/variables.scss';

.fullWidth {
	width: 100%;
	&,
	& .input {
		width: 100%;
	}
}

.autosizeText {
	display: inline-grid;
	vertical-align: top;
	align-items: center;
	position: relative;
	font: inherit;
	// font-size: var(--font-size-5);

	&::after {
		content: attr(data-value) ' ';
		visibility: hidden;
		white-space: pre-wrap;
	}
}

.autosizeText::after,
.input {
	width: auto;
	grid-area: 1 / 2;
	font: inherit;
	color: inherit;
	padding: 0;
	margin: 0;
	resize: none;
	background: none;
	border: none;
}

.input {
	font: inherit;
	appearance: textfield;
	outline: 0;
}

.input[type=number]::-webkit-inner-spin-button, 
.input[type=number]::-webkit-outer-spin-button { 
	-webkit-appearance: none;
	margin: 0; 
}