@import '../../style/variables';

.wrapper {
	display: flex;
	align-items: center;
	position: relative;
	cursor: pointer;
	padding: 0 0.25rem;
}

.control {
	display: none;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, 100%);
	z-index: 1;
}

.isVisible {
	display: block;
}