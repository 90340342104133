@use 'sass:color';
@import '../../style/variables.scss';

.textInput {
	display: flex;
	align-items: center;

	input {
		min-width: 5rem;
	}
}

.inputWrap {
	display: inline-flex;
	align-items: flex-end;
	font-size: $font-size-6;
	font-size: var(--input-font-size);
	padding-bottom: .05rem;
	border-bottom: 2px solid transparent;


	.highlight & {
		border-bottom: 1px solid color.change($color-greyscale-300, $alpha: 0.5);
	}

	.isFocused &,
	&:hover {
		border-bottom: 1px solid $color-greyscale-400;
	}

	.isInvalid & {
		border-bottom-color: $color-rest-400;
	}
}

.label {
	font-size: $font-size-4;
	color: $color-greyscale-700;
	margin-bottom: $pad-base;
}

.suffix {
	line-height: 1.5;
	font-size: $font-size-4;
	font-size: calc(var(--input-font-size) * .6);
	color: $color-greyscale-700;
	margin-left: .25rem;
}

:global(.darkMode) {
	.isInvalid .inputWrap {
		border-bottom-color: $color-rest-800;
	}
}