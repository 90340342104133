@import 'variables.scss';
@import 'reset.scss';
@import 'helpers.scss';

html, body {
	height: 100%;
	width: 100%;
	padding: 0;
	margin: 0;
	font-family: var(--font-family-sans);
	font-weight: 300;
	font-size: 10px;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background: $color-darkmode-greyscale-850;

	@include media(smaller) {
		font-size: 12px;
	}

	@include media(small) {
		font-size: 14px;
	}

	@include media(medium) {
		font-size: 16px;
	}

	@include media(large) {
		font-size: 20px;
	}

	@include media(larger) {
		font-size: 24px;
	}

	@include media(smallerLandscape) {
		font-size: 8px;
	}
}

.app {
	color: $color-greyscale-900;
	background: $color-greyscale-200;

	::selection {
		color: $color-greyscale-100;
		background: $color-greyscale-700;
	}

	&.darkMode {
		color: $color-darkmode-greyscale-200;
		background: $color-darkmode-greyscale-850;

		::selection {
			color: $color-darkmode-greyscale-800;
			background: $color-darkmode-greyscale-100;
		}
	}
}

* {
	box-sizing: border-box;
}

a {
	text-decoration: none;
	&,
	&:visited {
		color: inherit;
	}
}