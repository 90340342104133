@import "variables.scss";
@import "helpers.scss";

.document {
  display: flex;
  flex-direction: column;
  margin: 4rem auto;
  padding: 1rem;
  max-width: 42rem;

  h1 {
    font-size: $font-size-8;
    margin-bottom: 4rem;
  }

  h2 {
    font-size: $font-size-4;
    margin: 3rem 0 1rem;
  }

  h3 {
    margin: 2rem 0 1rem;
  }

  p {
    margin: 0.5rem 0;
  }

  strong,
  b {
    font-weight: 500;
  }

  a {
    color: $color-go-400;
  }

  a:hover {
    text-decoration: underline;
  }

  ol,
  ul {
    padding-left: 1rem;
    list-style-type: disc;
  }

  li {
    padding: 0.25rem 0;
  }
}
