@import '../../style/helpers';
@import '../../style/variables';


.floatingButton {
	display: flex;
	align-items: center;
	border-radius: $border-radius;
	color: $color-greyscale-400;
	box-shadow: $box-shadow;
	padding: .85rem 1.05rem;
	border: 0;
	background: $color-greyscale-100;
	cursor: pointer;
	font-size: $font-size-3;
	font-weight: 300;

	svg {
		fill: $color-greyscale-300;
		margin-right: .5rem;
		height: 1rem;
		width: 1.5rem;
	}

	@include hasHover() {
		&:hover {
			color: $color-greyscale-500;
			box-shadow: $box-shadow, $box-shadow;
			svg {
				fill: $color-greyscale-400;
			}
		}
	}

	&Primary {
		svg {
			fill: $color-go-500;
		}

		&:hover {
			svg {
				fill: $color-go-800;
			}			
		}
	}
}

.tooltipButton {
	background: none;
	border: 0;
	cursor: pointer;
	margin-left: .75rem;
	padding: 0;
	fill: $color-greyscale-300;

	&:hover {
		fill: $color-greyscale-400;
	}

	svg {
		width: 1.5rem;
	}
}

.iconToggle {
	display: flex;
	align-items: center;
	cursor: pointer;
	padding: 0 .25rem;
	svg {
		width: 1.75rem;
		height: 1.75rem;
		fill: $color-greyscale-300;
	}

	&:hover {
		svg {
			fill: $color-greyscale-400;
		}
	}
}

.playerControlButton {
	display: flex;
	flex-direction: column;
	align-items: center;
	flex: 0 1 auto;
	font-family: 'Roboto';
	font-weight: 400;
	font-size: inherit;
	background: $color-greyscale-100;
	color: var(--color-white);
	border-radius: 0;
	border: 0;
	outline: 0;
	padding: 1em 1.5em;
	margin-right: .5em;

	.text {
		display: none;
	}

	&:last-child {
		margin-right: 0;
	}

	svg {
		height: 2em;
		fill: $color-greyscale-300;
	}

	@include hasHover() {
		&:hover {
			svg {
				fill: $color-greyscale-500;
			}
		}
	}

	&:disabled {
		cursor: not-allowed;
		color: var(--color-contrast-medium);
		
		svg {
			fill: $color-greyscale-200;
		}
	}
}

.grow {
	flex: 1 1 auto;
}


:global(.darkMode) {
	.floatingButton {
		background: $color-darkmode-greyscale-900;
		color: $color-greyscale-600;
		box-shadow: $box-shadow-dark;

		svg {
			fill: $color-greyscale-600;
		}

		@include hasHover() {
			&:hover {
				color: $color-greyscale-100;
				box-shadow: $box-shadow-dark, $box-shadow-dark;
				svg {
					fill: $color-greyscale-100;
				}
			}
		}

		&Primary {
			svg {
				fill: $color-go-900;
			}

			&:hover {
				svg {
					fill: $color-go-300;
				}			
			}
		}
	}

	.iconToggle {
		svg {
			fill: $color-darkmode-greyscale-300;
		}
		&:hover {
			svg {
				fill: $color-darkmode-greyscale-200;
			}
		}
	}

	.playerControlButton {
		background: $color-darkmode-greyscale-800;

		svg {
			fill: $color-darkmode-greyscale-300;
		}

		@include hasHover() {
			&:hover {
				svg {
					fill: $color-darkmode-greyscale-100;
				}
			}
		}

		&:disabled {			
			svg {
				fill: $color-darkmode-greyscale-600;
			}
		}		
	}
}