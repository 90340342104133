@mixin media ($breakpoint) {

	@if $breakpoint==smaller {
		@media only screen and (min-width: $bp-smaller-w) {
			@content;
		}
	}

	@if $breakpoint==small {
		@media only screen and (min-width: $bp-small-w) {
			@content;
		}
	}

	@if $breakpoint==medium {
		@media only screen and (min-width: $bp-medium-w) {
			@content;
		}
	}

	@if $breakpoint==large {
		@media only screen and (min-width: $bp-large-w) {
			@content;
		}
	}

	@if $breakpoint==larger {
		@media only screen and (min-width: $bp-larger-w) {
			@content;
		}
	}

	@if $breakpoint==smallerWH {   

		@media only screen and (min-width: $bp-smaller-w) and (min-height: $bp-smaller-h) {
			@content;
		}
	}

	@if $breakpoint==smallWH {
		@media only screen and (min-width: $bp-smaller-w) and (min-height: $bp-small-h) {
			@content;
		}
	}

	@if $breakpoint==mediumWH {
		@media only screen and (min-width: $bp-smaller-w) and (min-height: $bp-medium-h) {
			@content;
		}
	}

	@if $breakpoint==largeWH {
		@media only screen and (min-width: $bp-small-w) and (min-height: $bp-large-h) {
			@content;
		}
	}

	@if $breakpoint==largerWH {
		@media only screen and (min-width: $bp-medium-w) and (min-height: $bp-larger-h) {
			@content;
		}
	}

	@if $breakpoint==smallerLandscape {
		@media only screen and (orientation: landscape) and (max-height: 410px) {
			@content;
		}
	}

	@if $breakpoint==mobileOnly {
		@media only screen and (hover: none) and (pointer: coarse) {
			@content;
		}
	}
}

@mixin hasHover() {
	@media (hover: hover) {
		@content;
	}
}


// replace substring with another string
// credits: https://css-tricks.com/snippets/sass/str-replace-function/
@function str-replace($string, $search, $replace: '') {
	$index: str-index($string, $search);
	@if $index {
		@return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
	}
	@return $string;
}

// return css color variable with different opacity value
@function alpha($color, $opacity){
	$color: str-replace($color, 'var(');
	$color: str-replace($color, ')');
	$color-h: var(#{$color+'-h'});
	$color-s: var(#{$color+'-s'});
	$color-l: var(#{$color+'-l'});
	@return hsla($color-h, $color-s, $color-l, $opacity);
}

@function lightness($color, $lightnessMultiplier){
	$color: str-replace($color, 'var(');
	$color: str-replace($color, ')');
	$color-h: var(#{$color+'-h'});
	$color-s: var(#{$color+'-s'});
	$color-l: var(#{$color+'-l'});
	@return hsl($color-h, $color-s, calc(#{$color-l} * #{$lightnessMultiplier}));
}

@function saturation($color, $saturationMultiplier){
	$color: str-replace($color, 'var(');
	$color: str-replace($color, ')');
	$color-h: var(#{$color+'-h'});
	$color-s: var(#{$color+'-s'});
	$color-l: var(#{$color+'-l'});
	@return hsl($color-h, calc(#{$color-s} * #{$saturationMultiplier}), $color-l);
}

@mixin defineColorHSL($color, $hue, $saturation, $lightness){
	#{$color}: unquote("hsl(#{$hue}, #{$saturation}, #{$lightness})");#{$color}-h: #{$hue};#{$color}-s: #{$saturation};#{$color}-l: #{$lightness};
}