@import '../../style/variables';

.share {
	position: relative;
}

.tooltip {
	display: flex;
	position: absolute;
	padding: .75rem 1rem;
	align-items: center;
	background: $color-greyscale-100;
	top: -99999%;
	left: -99999%;
	transform: translate(-50%, 1rem);
	z-index: 1;
	border-radius: $border-radius;
	box-shadow: $box-shadow;
	font-size: $font-size-2;

	&::after {
		position: absolute;
		top: 0;
		left: 50%;
		transform: translate(-50%, -50%);
		content: "";
		width: 0; 
		height: 0; 
		border-left: 1rem solid transparent;
		border-right: 1rem solid transparent;
		border-bottom: 1rem solid $color-greyscale-100;
	}

	input {
		max-width: 30ch;
	}
}


.isVisible {
	top: 100%;
	left: 50%;
}

:global(.darkMode) {
	.tooltip {
		background: $color-darkmode-greyscale-800;
		box-shadow: $box-shadow-dark;

		&::after {
			border-bottom-color: $color-darkmode-greyscale-800;
		}

		svg {
			fill: $color-darkmode-greyscale-300;

			&:hover {
				fill: $color-darkmode-greyscale-100;
			}
		}
	}
}